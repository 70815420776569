<template>
    <section class="llamados-listado position-relative w-100 px-4">
        <cargando v-if="cargando" />
        <div class="position-sticky bg-white py-4" style="top: 55px; z-index: 9">
            <div class="d-middle justify-content-between pl-2">
                <p class="f-20 f-500">Llamados de atención</p>
                <div class="d-middle gap-3">
                    <div>
                        <button class="btn btn-general h-32px f-14 f-300" @click="crearNuevaLlamado">
                            Hacer un llamado
                        </button>
                    </div>
                    <div class="wm-250px">
                        <el-input v-model="search" placeholder="Buscar" prefix-icon="el-icon-search" size="small" @change="buscador" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row overflow-auto custom-scroll" style="height:calc(100vh - 180px)">
            <el-table :data="llamados" height="calc(100vh - 215px)" style="width: 100%" @row-click="verLlamado">
                <el-table-column sortable prop="titulo" label="Nombre">
                    <template slot-scope="scope">
                        {{ scope.row.id }} - {{ scope.row.titulo }}
                    </template>
                </el-table-column>
                <el-table-column sortable prop="vivienda_padre" label="Grupo de viviendas" />
                <el-table-column sortable prop="vivienda" label="Vivienda" />
                <el-table-column sortable prop="created_at" label="Reporte">
                    <template slot-scope="scope">
                        {{ scope.row.created_at | helper-fecha('DD MMM YYYY') }}
                    </template>
                </el-table-column>
                <div slot="empty" class="row">
                    <div class="col-12 text-center">
                        <img src="/img/generales/sin_resultados.svg" alt="" width="120" height="120" />
                        <p class="text-sin-datos f-17 mx-auto lh-20 f-500 mt-3">No hemos encontrado coincidencias con tu búsqueda</p>
                    </div>
                </div>
                <infinite-loading
                slot="append"
                force-use-infinite-wrapper=".el-table__body-wrapper"
                @infinite="listar"
                />
            </el-table>
        </div>
    </section>
</template>
<script>
import InfiniteLoading from 'vue-infinite-loading';
import Llamados from '~/services/llamados'
export default {
    components:{
        InfiniteLoading
    },
    data(){
        return{
            llamados:[],
            search: '',
            page: 1,
            cargando : false
        }
    },
    watch:{
        // search(){
        //     this.page = 1
        //     this.llamados = []
        // }
    },
    methods:{
        crearNuevaLlamado(){
            this.$router.push({ name: 'llamados.crear' })
        },
        verLlamado(row){
            this.$router.push({ name: 'llamados.ver', params:{id_llamado : row.id} })
        },
        async listar($state){
            try {
                const state = $state
                this.cargando = true
                let params= {
                    page: this.page,
                    search: this.search,
                }
                const {data} = await Llamados.listar(params)
                if(data.data.data.length){
                    this.page += 1;
                    this.llamados.push(...data.data.data)
                    if (typeof state !== 'undefined'){
                        state.loaded()
                    }
                }else{
                    if (typeof state !== 'undefined'){
                        state.complete()
                    }
                }
            
            } catch (e){
                this.errorCatch(e)
            } finally{
                this.cargando = false
            }

        },
        async buscador(){
            this.page = 1
            this.llamados = []
            this.listar()
        },
    }
}
</script>
<style lang="scss" scoped>
.llamados-listado {
    position: relative;
}
</style>